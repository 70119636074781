import React from 'react'
import {Menu} from 'semantic-ui-react'
import Logo from './Logo'
import * as styles from './styles/header.module.css'


const DesktopMenu = () => {

	return (
		<Menu size="huge" secondary>
			<Menu.Item
				header
				className={styles.text}
			>
				<Logo />
				NegosyoNow
			</Menu.Item>
		</Menu>
	)
}

export default DesktopMenu