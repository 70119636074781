import React, { Component } from 'react'
import {Container, Header} from 'semantic-ui-react'
import { navigate } from "gatsby"
import Seo from '../components/SEO'
import Layout from '../components/Layout/layoutMod'
import * as styles from './styles/pages.module.css'
import ChangePasswordForm from '../components/Forms/changePasswordForm'

const Negosyo = require('../../lib/negosyo')
export default class ChangePassword extends Component {


	componentDidMount() {
		const {
			location
		} =this.props
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===false) {
				//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
				navigate(
					`/`,
					{
						replace:true,
						state: {
							customKey:this.props.location.key
						}
					}
				)
			}else {
				if(location.state) {
					if(!location.state.authorized) {
						navigate(
							`/`,
							{
								replace:true,
								state: {
									customKey:location.key
								}
							}
						)
					}
				}else {
					navigate(
						`/`,
						{
							replace:true,
							state: {
								customKey:location.key
							}
						}
					)
				}
			}
		})

	}



	render() {
		const location = this.props.location
		return (
			<Layout location={location}>
				<Seo title={"Change Password"} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						<Header className={styles.header}>Change Password</Header>
						<ChangePasswordForm location={location}/>
					</div>
				</Container>
			</Layout>
		)
	}
}
