import React, {Component} from "react"
import { navigate } from 'gatsby'
import {Button, Form, Message, Transition } from 'semantic-ui-react'
import * as styles from './styles/forms.module.css'
import {
	confirmationColor
} from '../../globalVar'

const Negosyo = require('../../../lib/negosyo')

export default class changePasswordForm extends Component {
	state={
		password:'',
		confirmPassword:'',
		showPass:false,
		showCPass:false,
		success: undefined, //not sure if this is really needed since the user will be redirected when successful
		error:undefined,
		loading:false,
		errorMessage: {
			password:undefined,
			confirmPassword:undefined
		}
	}

	handleInputChange = (e, {name, value}) => {
		this.setState({ [name]: value })
		if(value==='') {
			const message = name + " must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}


	validateInput = () => {
		let check = true;
		const { password, confirmPassword } = this.state
		const test = { password, confirmPassword}
		for( let key of Object.keys(test) ) {
			if(this.state[key]==='' ) {
				this.setState( prevState=>({
					errorMessage: {
						...prevState.errorMessage,
						[key]:"this must not be blank"
					}

				}))
				check = false
			}
		}

		//taken from the error message when trying to pass a password that either starts or ends with a whitespace character
		const re = /^[\S]+.*[\S]+$/

		if (password.length<6) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					password:"password length must be greater than 5"
				}
			}))
			check = false;
		} else if (!re.test(password) ) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					password:"password must not start or end with a space"
				}
			}))
			check = false
		} else if (password!==confirmPassword) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					confirmPassword:"Passwords must match"
				}
			}))
			check = false;
		}
		return check;
	}

	submitChangePassword = () => {
		const {password} = this.state
		const {location} = this.props
		if(this.validateInput()) {
			this.setState({loading:true})
			Negosyo.getCurrentUserDetails()
			.then((user)=>{
				Negosyo.negosyoChangePassword(user.username, password)
				.then((result)=>{
					if(result.status==="OK") {
						this.setState({loading:false})
						//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
						let customKey
						if(location.state) {
							if(location.state.customKey) {
								customKey=location.state.customKey
							}else {
								customKey=location.key
							}
						}else {
							customKey=location.key
						}
						navigate(
							`/my-account/`,
							{
								replace:true,
								state:{
									customKey:customKey
								}
							}
						)

					}else {
						this.setState({
							loading:false,
							error:"Unable to Change Password, Please try again"
						})
					}

				}).catch((error)=>{
					this.setState({
						loading:false,
						error:"Unable to Change Password, Please try again"
					})
				})
			}).catch(()=>{
				this.setState({
					loading:false,
					error:"Unable to Change Password"
				})
			})

		}
	}

	toggleShow = (pass) => {
		this.setState({
			[pass]:!this.state[pass]
		})
	}

	render() {
		const {
			password,
			confirmPassword,
			showPass,
			showCPass,
			errorMessage,
			error,
			success,
			loading
		} = this.state

		const {
			handleInputChange,
			toggleShow,
			submitChangePassword
		} = this

		let icon
		let icon2
		if(showPass) {
			icon="eye slash"
		} else {
			icon="eye"
		}
		if(showCPass) {
			icon2="eye slash"
		} else {
			icon2="eye"
		}

		return(
			<React.Fragment>

				<Form onSubmit={submitChangePassword}>

					<Form.Input
						label="Password"
						placeholder="minimum of 6 characters"
						type={showPass ? undefined:"password"}
						error={errorMessage.password}
						value={password}
						name='password'
						onChange={handleInputChange}
						action={{
							icon:icon,
							onClick: ()=>toggleShow("showPass"),
							type:"button"
						}}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					<Form.Input
						label="Confirm Password"
						placeholder="minimum of 6 characters"
						type={showCPass ? undefined:"password"}
						error={errorMessage.confirmPassword}
						value={confirmPassword}
						name='confirmPassword'
						onChange={handleInputChange}
						action={{
							icon: icon2,
							onClick: ()=>toggleShow("showCPass"),
							type:"button"
						}}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					{error && (
						<Message negative className={styles.msg}>
							<Message.Header>{error}</Message.Header>
							Please try again
						</Message>
					)}
					{success && (
						<Transition duration={{hide: 500, show: 500}} visible={success ? true:false}>
							<Message positive className={styles.msg}>
								<Message.Header>Success</Message.Header>
								{this.state.success}
							</Message>
						</Transition>
					)}
					<Button
						color={confirmationColor}
						className={styles.form}
						loading={loading}
						disabled={loading}
					>
						Proceed
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}




