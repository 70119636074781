import React from 'react'
import Header from '../Header/HeaderMod'
//import {Container} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import * as styles from './styles/layout.module.css'
import {Amplify} from "aws-amplify"
import config from '../../aws-cognito'
Amplify.configure(config) //not sure if this really is needed since to get here one must go through the normal layout

const LayoutMod = ({location, loggedIn, children}) => (
	<>
		<div className={styles.header}>
			<Header location={location} loggedIn={loggedIn} />
		</div>
		<div className={styles.content}>
			{children}
		</div>
	</>
)

export default LayoutMod

